import { useTranslation, Trans } from 'react-i18next';
import {useGlobalState} from "../GlobalState"
import '../App.css';
import { useEffect } from 'react';
import { loadFromLocalStorage, addTimeStamp } from '../utils';
import { Band, Pictures } from '.';
import { ImportantDate } from '../components/important-date';
import { SocialLinks } from '../components/socialLinks';
import { AmberLogo } from '../components/amberLogo';

export const SinglePageView = () => {
  const {t} = useTranslation();
  // eslint-disable-next-line
  const [state, dispatch] = useGlobalState();

  useEffect(() => {
    dispatch( loadFromLocalStorage() );
  },[dispatch]);

  const releaseDate = <ImportantDate text={t('media.music_new_album_date')}/>;
  
  return (
    <div id='main-fixed'>
      
      <header className="App-header">

        <img src={addTimeStamp('assets/amberane-name.png')} alt='ffh' />
        
        <div id="shop">
          <AmberLogo logoWidth='5vw' logoOpacity='0.5' />
          <p>{t( 'media.music_new_album' )} {releaseDate}</p>
          <img src={addTimeStamp('assets/cover-front-web-opt.jpg')} alt='new-album-cover' style={{width: '35vw'}} />
          <div className='album-art'>
            <img src={addTimeStamp('assets/cover-front-web-opt.jpg')} alt='new-album-cover-1' style={{width: '25vw'}} />
            <img src={addTimeStamp('assets/cover-telephone-web-opt.jpg')} alt='new-album-cover-2' style={{width: '25vw'}} />
            <img src={addTimeStamp('assets/cover-band-web-opt.jpg')} alt='new-album-cover-3' style={{width: '25vw'}} />
            <img src={addTimeStamp('assets/cover-songs-web-opt.jpg')} alt='new-album-cover-4' style={{width: '25vw'}} />
          </div>
        </div>

        <div className="App-welcome">
          <p style={{margin: 0}}>{ t( 'home.teaser-text-video-above' ) }</p>
          <div className="container">
            <iframe className="responsive-iframe" allowFullScreen="allowFullScreen" src='https://www.youtube.com/embed/egf8h_mVoJ8?autoplay=1&mute=0' title='NEW VIDEO'></iframe>
          </div> 
          <p style={{margin: 0}}><Trans i18nKey="home.teaser-text-video-below"></Trans>{releaseDate}</p>
          <AmberLogo logoWidth='10vw' logoOpacity='0.5' />
        </div>

      </header>

      <div id='stream' className='streaming-wrap'>
        <img src={addTimeStamp('assets/amberane-name.png')} alt='ffh2' style={{width: '25vw'}} />
        <p>New album 'Far From Home' - {releaseDate} available on all platforms</p>
        <ul className="streaming-links">
          <li><a href="https://open.spotify.com/intl-de/album/14glVLmDS2ic8g1dPMCKrW?si=i9WFxXLAQaOLuPjwS7uh9g" target="_blank" rel="noreferrer" className="spotify"><i className="fab fa-spotify"></i> Spotify</a></li>
          <li><a href="https://www.apple.com/music" target="_blank" rel="noreferrer" className="apple"><i className="fab fa-apple"></i> Apple Music</a></li>
          <li><a href="https://deezer.page.link/zw2wpH8ms2qcSzQ89" target="_blank" rel="noreferrer" className="deezer"><i className="fab fa-deezer"></i> Deezer</a></li>
          <li><a href="https://music.amazon.de/artists/B0D646DRWN/amberane" target="_blank" rel="noreferrer" className="amazon"><i className="fab fa-amazon"></i> Amazon Music</a></li>
          <li><a href="https://amberane.bandcamp.com/" target="_blank" rel="noreferrer" className="bandcamp"><i className="fab fa-bandcamp"></i> Bandcamp</a></li>
        </ul>
        <SocialLinks />
      </div>
      <div id='band-main-image'>
        <img src={addTimeStamp('assets/1_band-main.jpg')} alt='band-main' width={'100%'} style={{opacity: 0.3}} />
        <AmberLogo logoWidth='15vw' logoOpacity='0.5' />
      </div>
      
      <div id="band" className='main-panel'>
        <Band/>
      </div>
      <div id="media" className='main-panel' >
        <Pictures/>
      </div>
      <div id='bandcamp-wrap'>
        <iframe title='Far From Home bandcamp' style={{border: '1px solid #f0edbb', height: '772px'}} src="https://bandcamp.com/EmbeddedPlayer/album=3836094270/size=large/bgcol=333333/linkcol=e99708/transparent=true/" seamless><a href="https://amberane.bandcamp.com/album/far-from-home">Far From Home by AMBERANE</a></iframe>
        {/* <iframe title='Far From Home - spotify' style={{borderRadius: '12px'}} src="https://open.spotify.com/embed/album/14glVLmDS2ic8g1dPMCKrW?utm_source=generator&theme=0" width="320px" height="785"  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe> */}
      </div>
      <div id='contact'>
        <AmberLogo logoWidth='20vw' logoOpacity='0.5' />
        <p style={{paddingBottom: 20, margin: 0}}>Mail: <a href='mailto:massentonhaltung@gmail.com'>massentonhaltung@gmail.com</a></p>
      </div>
    </div>
  );
}